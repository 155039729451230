.hide {
  opacity: 0;
  display: none;
}

.show {
  opacity: 1;
  display: block;
}

.spacer-1 {
  min-height: 3rem;
}

.spacer-2 {
  min-height: 7rem;
}

@keyframes fadeOut {
  30% {
    opacity: .7;
  }

  80% {
    opacity: .2;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  80% {
    opacity: .2;
  }

  100% {
    opacity: 1;
  }
}

.fadeOut {
  animation: .2s ease-in forwards fadeOut;
}

.fadeIn {
  animation: .2s ease-in forwards fadeIn;
}

.hero {
  background: #0009;
}

.heroImage {
  background-image: linear-gradient(#000000a6, #000000a6), url("home.48805d4b.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 45rem;
  position: relative;
}

.title {
  color: #fff;
  width: 50%;
  margin-top: 4%;
  font-size: 2.5em;
  font-weight: 300;
}

.subtitle {
  color: #0090d2;
  margin-top: 0;
  font-size: 2em;
  font-style: italic;
}

.highlightContainer {
  flex-flow: wrap;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 15%;
  display: flex;
}

.highlightIcon {
  color: #3eb0a7;
  border: 1px solid #fff;
  border-radius: 50%;
  margin-bottom: 10%;
  padding: 5%;
  font-size: 4em;
}

.highlightText {
  color: #fff;
  font-size: 1.7em;
}

.partnershipDetail {
  min-width: 25rem;
  max-width: 25rem;
  margin-bottom: 2%;
}

.partnership-model {
  flex-flow: wrap;
  align-items: flex-start;
  display: flex;
}

/*# sourceMappingURL=index.c70a451f.css.map */
