.hide {
    display: none;
    opacity: 0;
}

.show {
    display: block;
    opacity: 1;
}

.spacer-1 {
    min-height: 3rem;
}

.spacer-2 {
    min-height: 7rem;
}