@keyframes fadeOut {
    30%{
        opacity: .7;
    }
    80%{                       
        opacity: .2;
    }
    100%{        
        opacity: 0;
    }
}

@keyframes fadeIn {
    0%{        
        opacity: 0;
    }
    80%{       
        opacity: .2;
    }
    100%{        
        opacity: 1;
    }
}


.fadeOut {
    animation: .2s ease-in 0s forwards fadeOut;
}

.fadeIn {
    animation: .2s ease-in 0s forwards fadeIn;
}