@use '../../styles/colors.scss';
@use '../../utils/display.scss';
@use '../../utils/animation.scss';
//@use 'bulma/sass/utilities/initial-variables';



.hero {
  background: rgba(0,0,0, 0.6);
}

.heroImage {
  /* Use "linear-gradient" to add a darken background effect to the image (photographer.jpg). This will make the text easier to read */
  background-image: linear-gradient(rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)), url("../../images/home.jpg");
 
  /* Set a specific height */
  min-height: 45rem;
  /* Position and center the image to scale nicely on all screens */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.header{
}
.title {
  margin-top:4%;
  font-size: 2.5em;
  color:#fff;
  width: 50%;
  font-weight: 300;
  
}

.subtitle{
  margin-top: 0;
  font-size: 2em;
  color: colors.$blue;
  font-style: italic;
}

.highlightContainer {
  margin-top:15%;
  
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;

}




.highlightIcon {
  border: 1px solid #fff;
  border-radius: 50%;
  padding:5%;
  color:colors.$green;
  font-size: 4em;
  margin-bottom: 10%;
  
}

.highlightText {
  color:#fff;
  font-size: 1.7em;
}

.partnershipDetail {
  //background-color: initial-variables.$grey-dark;
  //color:ini;
  //flex-basis: 20rem;
  //flex-grow:1;
  margin-bottom: 2%;
  max-width: 25rem;
  min-width: 25rem;


}

.partnership-model {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  
  //is-flex is-flex-direction-row is-align-items-flex-start is-flex-wrap-wrap
}
